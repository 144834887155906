<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="wrapper-sm">
      <e-row class="erp-row-col-md">

        <e-col style="max-width: 200px">
          <erp-s-field
              view="lr"
              label="Código:"
              label-width="90px"
          >
            <erp-input simple-border v-model="local.id" readonly />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 111px">
          <tipo-vaga-select label="Tipo:" required field-view="lr" v-model="local.tipoVaga" autofocus></tipo-vaga-select>
        </e-col>

        <e-col style="max-width: 200px">
          <patio-select label="Pátio:" required field-view="lr" v-model="local.patio"></patio-select>
        </e-col>

        <e-col style="max-width: 180px">
          <erp-s-field view="lr" label="Status">
            <erp-select :options="[{label: 'Ativo', value: true},{label: 'Inativo', value: false}]"
                        v-model="local.active" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row class="erp-row-col-md">
        <e-col style="max-width: 405px">
          <erp-s-field
              view="lr"
              label="Nome do local:"
              label-width="90px"
          >
            <erp-input simple-border v-model="local.nome" />
          </erp-s-field>
        </e-col>

        <div class="col" style="max-width: 123px">
          <sigla-patio-input v-model="local.sigla" local></sigla-patio-input>
        </div>

        <e-col style="max-width: 180px">
          <erp-s-field
              view="lr"
              label="Localização"
          >
            <erp-select :options="[{label: 'Externo', value: true}, {label: 'Interno', value: false}]" v-model="local.externo" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="erp-row-col-md">
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="Andares:"
              label-width="90px"
          >
            <erp-input simple-border v-model="local.andares" type="number"/>
          </erp-s-field>
        </e-col>

        <e-col>
          <erp-s-field
              view="lr"
              label="Vagas por andar:"
          >
            <erp-input simple-border v-model="local.quantidadeVagasPorAndar" type="number"/>
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 250px">
          <erp-s-field
              view="lr"
              label="Total de vagas:"
          >
            <erp-input simple-border v-model="stats.total" readonly disabled />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 250px">
          <erp-s-field
              view="lr"
              label="Ocupadas:"
          >
            <erp-input simple-border v-model="stats.ocupadas" readonly disabled />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 250px">
          <erp-s-field
              view="lr"
              label="Disponíveis:"
          >
            <erp-input simple-border v-model="stats.disponiveis" readonly disabled />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-t-lg">
        <e-col style="max-width: 5px">
          <span style="font-weight: bold">Filtros:</span>
        </e-col>
        <e-col style="max-width: 180px">
          <erp-s-field
              view="lr"
              label-width="90px"
              tag="div"
          >
            <erp-checkbox class="flex items-center" v-model="filtros.ocupadas" @click.native="pesquisar">
              <label class="no-select font-bold font-11">
                <span class="m-l-xs">Ocupadas</span>
              </label>
            </erp-checkbox>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              tag="div"
          >
            <erp-checkbox class="flex items-center" v-model="filtros.disponiveis" @click.native="pesquisar">
              <label class="no-select font-bold font-11">
                <span class="m-l-xs">Disponíveis</span>
              </label>
            </erp-checkbox>
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 120px">
          <erp-s-field
              view="lr"
              label="Andar"
          >
            <erp-select :options="listaAndares" v-model="filtros.andar" @input="pesquisar" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 300px">
          <erp-s-field
              view="lr"
              label="Pesquisar:"
              label-width="90px"
          >
            <erp-input simple-border v-model="filtros.search" @keyup="pesquisar" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row>
        <table-vagas :lista="vagas" :loading="loadingVagas"></table-vagas>
        <simple-paginator
            :current-page="serverPagination.page"
            :total-results="serverPagination.total"
            :limit="serverPagination.rowsPerPage"
            @changePage="changePage"
        ></simple-paginator>
      </e-row>
    </div>

    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Local registrado, e vagas geradas com sucesso!</h5>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  WindowContent,
  // WindowHeader,
  // WindowBody,
  WindowFooter,
  // ErpTabItem,
  // ErpTabs,
  ErpSField,
  ErpInput,
  ErpSelect,
  ErpCheckbox
  // HelperInputBtn,
  // ErpBox
  // ErpLabel
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import WindowSuccess from '../../../layout/window/Success'
import {
  newLocalPatio,
  updateLocalPatio,
  find,
  listaVagasPatioLocal
} from "@/domain/patio/services/patio-local";
import ECol from '../../../layout/components/Col'
import ERow from '../../../layout/components/Row'
import PatioSelect from "../include/PatioSelect";
import TipoVagaSelect from "../include/TipoVagaSelect";
import TableVagas from "../include/tables/TableVagas";
import {debounce} from "uloc-vue";
import MontaFiltrosMixins from "../../../../mixins/MontaFiltrosMixins";
import SimplePaginator from "../include/SimplePaginator";
import SiglaPatioInput from "../include/SiglaPatioInput";

const mockLocal = {
  id: null,
  andares: 1,
  nome: null,
  sigla: null,
  tipoVaga: null,
  vagas: [],
  quantidadeVagasPorAndar: 0,
  externo: false
}

export default {
  name: 'PatioLocalWindow',
  mixins: [MontaFiltrosMixins],
  props: ['id'],
  components: {
    SiglaPatioInput,
    SimplePaginator,
    TableVagas,
    TipoVagaSelect,
    PatioSelect,
    ERow,
    ECol,
    WindowSuccess,
    TimerCloseWindow,
    WindowLoading,
    WindowFooter,
    // WindowBody,
    // WindowHeader,
    WindowContent,
    ErpSField,
    ErpInput,
    ErpSelect,
    ErpCheckbox
    // HelperInputBtn,
    // ErpLabel
  },
  data () {
    return {
      local: JSON.parse(JSON.stringify(mockLocal)),
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      cache: {},
      stats: {
        ocupadas: 0,
        disponiveis: 0,
        total: 0
      },
      filtros: {
        andar: null,
        disponiveis: false,
        ocupadas: false,
        search: null
      },
      serverPagination: {
        page: 1,
        rowsNumber: 0, // specifying this determines pagination is server-side
        rowsPerPage: 20
      },
      vagas: [],
      loadingVagas: false
    }
  },
  computed: {
    listaAndares () {
      const list = []
      for (let i = 1; i <= this.local.andares; ++i) {
        list.push({label: i, value: i})
      }
      return [{label: 'Qualquer', value: null}, ...list]
    }
  },
  created () {
    this.id && this.load()
    this.id && this.loadVagas(this.serverPagination.rowsPerPage, this.serverPagination.page, this.montaFiltros(this.filtros))
  },
  methods: {
    load () {
      return find(this.id || this.local.id)
          .then(response => {
            let data = response.data

            if (data.tipoVaga && data.tipoVaga.id) {
              data.tipoVaga = data.tipoVaga.id
            }
            data.patio = data.patio?.id

            const cache = data.statsVagasCache
            data.quantidadeVagasPorAndar = +cache.total / data.andares
            this.stats.ocupadas = +cache.ocupadas
            this.stats.disponiveis = +cache.disponiveis
            this.stats.total = +cache.total

            this.local = data
            this.$nextTick(() => {
              this.loading = false
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar o patio, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    loadVagas (limit = 1000, page = 1, filtros = '') {
      this.loadingVagas = true
      // this.vagas = []
      listaVagasPatioLocal(this.id, limit, page, filtros)
          .then(response => {
            this.vagas = response.data.result
            this.serverPagination.total = +response.data.total
          })
          .catch(error => {
            this.alertApiError(error)
          })
          .finally(() => {
            this.loadingVagas = false
          })
    },
    pesquisar: debounce(function () {
      const {rowsPerPage, page} = this.serverPagination
      this.loadVagas(rowsPerPage, page, this.montaFiltros(this.filtros))
    }, 500),
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.local))

      this.status = 'Processando dados do local'
      let method = data.id ? updateLocalPatio(data.id, data) : newLocalPatio(data)
      method
          .then(({data}) => {
            this.local.id = data.id
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                this.$refs.timer.start()
                // this.load()
                window.setTimeout(() => {
                  this.loading = false
                  this.success = false
                }, 3000)
                this.$uloc.window.emit(this.$root.wid, 'update', this.props)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
              // this.error = true
            })
          })
    },
    changePage (newPage) {
      this.serverPagination.page = newPage
      this.pesquisar()
    }
  }
}
</script>
