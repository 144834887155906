<template>
  <erp-s-field
      :view="this.fieldView || 'tl'"
      :label="label || 'Pátio'"
      :label-width="labelWidth"
      :helper="helper"
      :error="error"
      :helper-position="helperPosition || 'lb'"
  >
    <erp-select
        @input="__set"
        :loading="loading"
        :placeholder="placeholder || 'Selecione'"
        :options="asyncdataFiltered"
        v-model="model"
        :multiple="multiple"
        :simpleBorder="simpleBorder"
    ></erp-select>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import {list} from '../../../../domain/patio/services/index'

export default {
  name: 'PatioSelect',
  mixins: [SelectMixin],
  props: {
    listAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      asyncdata: []
    }
  },
  computed: {
    asyncdataFiltered () {
      return this.listAll ? this.asyncdata : this.asyncdata.filter(item => !item.parent)
    }
  },
  watch: {
    model (v) {
      this.$nextTick(() => {
        if (!this.model) {
          return
        }
        let selected = this.asyncdata.filter(item => item.id === this.model)
        if (!selected) {
          return
        }
        this.$emit('selected', selected[0])
      })
    }
  },
  mounted () {
    this.load()
  },
  components: {},
  methods: {
    load () {
      this.loading = true
      list(1000, 1, '') // TODO: LIMIT
          .then((response) => {
            this.loading = false
            if (!Array.isArray(response.data.result)) {
              return
            }
            this.$emit('load', response.data.result)
            let list = response.data.result.map((obj) => {
              return {
                ...obj,
                label: `${obj.sigla} - ${obj.nome}`,
                value: obj.id
              }
            })

            !this.multiple && !this.required && list.unshift({label: this.placeholder || 'Selecione', value: null})
            this.asyncdata = list
          })
          .catch((error) => {
            this.loading = false

          })
    }
  }
}
</script>
