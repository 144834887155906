<template>
  <div class="col-grow flex justify-between h-full">
    <div class="table-loading" v-if="loading"></div>
    <e-window-table
        class="col-grow h-full h-limited"
        :columns="['ID', 'Código', 'Ocupação atual', 'Ocupação anterior', 'Opções']"
    >
      <e-tr v-for="(data, index) in lista" :key="index">
        <e-td><span>{{ String(data.id).padStart(4, '0') }}</span></e-td>
        <e-td>{{data.codigo}}</e-td>
        <e-td v-if="!data.ocupado"><span class="text-green">Livre</span></e-td>
        <e-td v-else>{{(data.bem || {}).descricao || 'Não há registro de bem'}}</e-td>
        <e-td v-if="!data.ocupado || !data.historicoSaidas || !data.historicoSaidas.length">Nenhuma</e-td>
        <e-td v-else>{{(last(data.historicoSaidas).bem || {}).descricao || '-'}}</e-td>
        <e-td>
          <a @click="windowBem(data.bem.id)" v-if="data.bem"><i class="erp-icon search min"></i> </a>
          <!--<u-icon name="times" type="fa" icon-style="regular" class="text-negative text-md m-l cursor-pointer m-b-xs" @click.native="remove(data)"></u-icon>-->
        </e-td>
      </e-tr>
      <e-tr v-if="!loading && (!lista || !lista.length)">
        <e-td style="text-align: left !important;" colspan="100%">
          Nenhum registro
        </e-td>
      </e-tr>
    </e-window-table>
  </div>
</template>

<script>
import {
  EWindowTable,
  ETd,
  ETr
} from 'uloc-vue-plugin-erp'
import windowBem from '../../../../bem/windows/bem'

export default {
  name: "TableVagas",
  props: {
    lista: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  components: {
    EWindowTable,
    ETd,
    ETr
  },
  methods: {
    remove (data) {
      console.log(data)
      this.$emit('remove', data)
    },
    adicionaLocal () {
      this.$emit('adicionaLocal')
    },
    last (array = []) {
      if (!array || !array.length) return {}
      return array[array.length - 1]
    },
    windowBem
  }
}
</script>

<style scoped lang="stylus">
@import "../../assets/style.css"
.h-limited {
  max-height: 280px;
  overflow: auto;
}
</style>
